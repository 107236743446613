import { makeStyles } from "@mui/styles";
import { lighten, Theme } from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
  },
  content: {
    position: "relative",
  },
  editor: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: "4px",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    backgroundColor: theme.palette.background.paper,
    transition: "all 0.2s ease-in-out",
    whiteSpace: "pre-wrap",
    position: "relative",
    lineHeight: "1.4375em",
    "&:focus-within": {
      outline: "none",
      borderColor: "#76a9fa",
      boxShadow: "0 0 0 3px rgba(118,169,250,.45)",
    },
    "&:empty::before": {
      content: "attr(data-placeholder)",
      color: theme.app.palette.action.placeholder,
      position: "absolute",
      pointerEvents: "none",
    },
  },
  error: {
    color: theme.palette.error.main,
    fontSize: "0.75rem",
    marginTop: "3px",
  },
  highlightedText: {
    backgroundColor: lighten(theme.palette.secondary.light, 0.7),
    color: theme.palette.text.primary,
    padding: "1px",
    margin: "0 .5px",
    borderRadius: "2px",
  },
  highlightedIfElse: {
    backgroundColor: lighten(theme.palette.warning.light, 0.7),
    color: theme.palette.text.primary,
    padding: "1px",
    margin: "0 .5px",
    borderRadius: "2px",
  },
  multiline: {
    minHeight: 140,
    marginBottom: theme.spacing(3),
    padding: "10px",
    "&:empty::before": {
      top: "10px",
      left: "10px",
    },
  },
  singleline: {
    padding: "12px",
    "&:empty::before": {
      top: "12px",
      left: "12px",
    },
  },
  preview: {
    paddingRight: "51%",
  },
  endAdornment: {
    position: "absolute",
    right: "10px",
    bottom: "3px",
  },
}));
