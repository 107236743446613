import React, { useMemo } from "react";
import { useQuery } from "react-query";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Theme, lighten } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import AccountService from "modules/Account/services";
import { AccountContext } from "modules/Account/context";
import { getAccountPlaceholders } from "modules/Placeholder/utils";
import { AccountQuery } from "modules/Account/models";
import PlaceholderButton from "../PlaceholderButton";
import { PlaceholderMenuProps } from "modules/Placeholder/models";

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    borderRadius: 0,
  },
  placeholderItem: {
    "&:hover": {
      backgroundColor: lighten(theme.palette.secondary.main, 0.7),
    },
  },
  paper: {
    width: 180,
    maxHeight: 400,
  },
  list: {
    overflowY: "scroll",
    height: "100%",
    ...theme.app.constants.scrollbar,
  },
}));

interface AccountPlaceholderMenuProps {
  handleOnSelect: PlaceholderMenuProps["handleOnSelect"];
}

const AccountPlaceholderMenu = ({
  handleOnSelect,
}: AccountPlaceholderMenuProps): React.ReactElement => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const { data } = useQuery(
    [AccountQuery.account, accountId],
    async () => {
      try {
        const response = await AccountService.fetchAccount(accountId);
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const accountPlaceholders = useMemo(
    () =>
      data?.template_placeholders
        ? getAccountPlaceholders(data.template_placeholders)
        : [],
    [data]
  );

  const isMenuOpen = React.useMemo(() => Boolean(anchorEl), [anchorEl]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <PlaceholderButton
        label="Sender"
        handleOnClick={handleMenuOpen}
        disabledButton
      />
      <Menu
        autoFocus={false}
        anchorEl={anchorEl}
        id="account-placeholder-menu"
        keepMounted
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
        PaperProps={{
          className: classes.paper,
        }}
        classes={{
          list: classes.list,
        }}
      >
        {accountPlaceholders.map((option) => (
          <MenuItem
            className={clsx(classes.item, classes.placeholderItem)}
            onClick={() => {
              handleOnSelect(option);
              handleMenuClose();
            }}
            key={option.name}
            value={option.name}
          >
            {option.id}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default AccountPlaceholderMenu;
