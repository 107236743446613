import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { FeatureListItem } from "./FeatureListItem";

const useStyles = makeStyles((theme) => ({
  featureList: {
    marginBottom: theme.spacing(3),
    width: "100%",
  },
}));

const featureItems = [
  "Send up to **300 connection requests** per week",
  "Collect new leads from **+800M LinkedIn profiles**",
  "**Keep your account safe** with our advanced security",
];

export const FeatureList = (): React.ReactElement => {
  const classes = useStyles();

  return (
    <Box className={classes.featureList}>
      {featureItems.map((item, index) => (
        <FeatureListItem key={index} text={item} />
      ))}
    </Box>
  );
};
