import { SEARCH_STRATEGY_MAX_RESULTS } from "modules/Search/constants";
import { ISearch, SearchStatusMatchToVariant } from "modules/Search/models";
import SearchService from "modules/Search/services";
import React, { useMemo } from "react";
import { useQuery } from "react-query";
import ProgressBar, {
  BaseProgressBarProps,
} from "ui-kit/components/ProgressBar";

interface SearchProgressProps extends BaseProgressBarProps {
  search: ISearch;
}

const createTotalObj = (
  totalCount: number | undefined,
  totalExtraLabel: string | undefined
) => ({
  totalCount,
  totalExtraLabel,
});

const getTotalObj = (
  status: ISearch["status"],
  strategy: ISearch["strategy"],
  totalCount: number | undefined,
  strategyTotal: number | undefined
) => {
  if (status === SearchStatusMatchToVariant.processed) {
    return createTotalObj(totalCount, undefined);
  }
  if (strategyTotal) {
    return createTotalObj(strategyTotal, undefined);
  }
  if (SEARCH_STRATEGY_MAX_RESULTS[strategy]) {
    return createTotalObj(SEARCH_STRATEGY_MAX_RESULTS[strategy], "up to ");
  }
  return createTotalObj(totalCount, undefined);
};

const SearchProgress = ({
  search,
  ...rest
}: SearchProgressProps): React.ReactElement => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { id: searchId, strategy, status, strategy_data } = search;

  const fetchSearchResultsCount = async () => {
    try {
      const { data } = await SearchService.fetchCountSearchResults(searchId);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: dataCount } = useQuery(
    ["search-results", { total: "count" }, searchId],
    () => fetchSearchResultsCount(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 30 * 1000, // 30 seconds
      enabled: !!searchId,
    }
  );

  const { totalCount, totalExtraLabel } = useMemo(
    () =>
      getTotalObj(
        status,
        strategy,
        status === SearchStatusMatchToVariant.processing
          ? search?.strategy_data?.total
          : dataCount?.count,
        strategy_data?.total
      ),
    [
      status,
      strategy,
      search?.strategy_data?.total,
      dataCount?.count,
      strategy_data?.total,
    ]
  );

  const isLoading = useMemo(
    () => !(dataCount?.count !== undefined && totalCount !== undefined),
    [dataCount?.count, totalCount]
  );

  const maxTotal = useMemo(
    () => SEARCH_STRATEGY_MAX_RESULTS[search.strategy],
    [search.strategy]
  );

  return (
    <ProgressBar
      count={dataCount?.count}
      total={Number(maxTotal) < Number(totalCount) ? maxTotal : totalCount}
      totalExtraLabel={totalExtraLabel}
      isLoading={isLoading}
      {...rest}
    />
  );
};

export default SearchProgress;
