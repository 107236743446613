import ConfigConstant from "core/constants/ConfigConstant";
import ButtonGroupFilter from "core/filters/atoms/ButtonGroupFilter";
import KeywordFilter from "core/filters/atoms/KeywordFilter";
import RouterUtils from "core/routes/utils";
import { createTableCell } from "core/utils/tableHandler";
import { isEqual } from "lodash";
import { AccountContext } from "modules/Account/context";
import { EXECUTION_FILTER_OPTIONS } from "modules/Campaign/constants";
import useCreateCampaign from "modules/Campaign/hooks/useCreateCampaign";
import { CampaignQuery, ExecutionKeys } from "modules/Campaign/models";
import CampaignService from "modules/Campaign/services";
import { getStatusChipData, navigateToCampaign } from "modules/Campaign/utils";
import InteractionCompareLabel from "modules/Interaction/components/InteractionCompareLabel";
import React, { useContext, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { Chip } from "ui-kit/atoms/Chip";
import ProgressBar from "ui-kit/components/ProgressBar";
import Table from "ui-kit/components/Table";
import TableBodyCell from "ui-kit/components/TableBodyCell";

const rowsPerPage = ConfigConstant.PAGE_SIZE.MEDIUM;
const title = "Campaigns";
const heads = [
  {
    id: "name",
    percentage: true,
    width: 30,
    label: "Name",
  },
  {
    id: "status",
    percentage: true,
    width: 16,
    label: "Status",
  },
  {
    id: "prospects",
    percentage: true,
    width: 20,
    label: "Prospects",
  },
  {
    id: "accept-rate",
    percentage: true,
    width: 17,
    label: "Accepted",
  },
  {
    id: "reply-rate",
    percentage: true,
    width: 17,
    label: "Replied",
  },
];

const CampaignListTable = (): React.ReactElement => {
  const { mutate: createCampaign } = useCreateCampaign();

  const [campaignIds, setCampaignIds] = React.useState<number[]>([]);

  const {
    account: { id: accountId },
  } = useContext(AccountContext);

  const location = useLocation();
  const { search, status } = RouterUtils.getQueryParams(location);
  const [page, setPage] = useState<number>(ConfigConstant.INITIAL_PAGE);

  const [statusQuery, setStatusQuery] = React.useState(
    RouterUtils.generateFilterOptionQuery(status, EXECUTION_FILTER_OPTIONS)
  );

  React.useEffect(() => {
    const newStatusQuery = RouterUtils.generateFilterOptionQuery(
      status,
      EXECUTION_FILTER_OPTIONS
    );
    setStatusQuery(newStatusQuery);
  }, [status]);

  const fetchCampaigns = async (p: number) => {
    try {
      const { data } = await CampaignService.fetchCampaigns(
        p,
        rowsPerPage,
        accountId,
        search,
        statusQuery
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data, isFetching } = useQuery(
    [CampaignQuery.campaigns, accountId, page, search, statusQuery],
    () => fetchCampaigns(page),
    {
      keepPreviousData: true,
      // refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  React.useEffect(() => {
    const newCampaignIds = data?.results.map((campaign) => campaign.id) || [];
    if (!isEqual(campaignIds, newCampaignIds)) {
      setCampaignIds(newCampaignIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const fetchCampaignStats = async (p: number) => {
    try {
      const { data } = await CampaignService.fetchCampaignStats(campaignIds);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: dataStats, isLoading: isLoadingStats } = useQuery(
    [CampaignQuery.campaigns_stats, accountId, campaignIds],
    () => fetchCampaignStats(page),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!campaignIds.length,
    }
  );

  const isLoading = useMemo(
    () => !dataStats || isLoadingStats,
    [dataStats, isLoadingStats]
  );

  return (
    <Table
      title={title}
      heads={heads}
      filters={
        <>
          <ButtonGroupFilter
            id="status"
            options={[
              { id: "", name: "All", query: {} },
              ...EXECUTION_FILTER_OPTIONS,
            ]}
          />
          <KeywordFilter />
        </>
      }
      rows={
        data
          ? data.results.map((campaign) => {
              const {
                label,
                icon: Icon,
                color,
              } = getStatusChipData(campaign.execution);
              const link = navigateToCampaign(campaign);
              return {
                name: campaign.id.toString(),
                data: [
                  createTableCell(campaign.name, link, "main", TableBodyCell),
                  createTableCell(
                    <Chip
                      variant="outlined"
                      size="small"
                      color={color}
                      icon={<Icon />}
                      label={label}
                    />,
                    link,
                    "element",
                    TableBodyCell
                  ),
                  createTableCell(
                    <ProgressBar
                      count={dataStats?.counts.active?.[campaign.id] || 0}
                      total={dataStats?.counts.all?.[campaign.id] || 0}
                      isLoading={isLoading}
                    />,
                    link,
                    "action",
                    TableBodyCell
                  ),
                  createTableCell(
                    campaign.execution === ExecutionKeys.DR ? (
                      "-"
                    ) : (
                      <InteractionCompareLabel
                        isLoading={isLoading}
                        count={
                          dataStats?.counts.invites_accepted?.[campaign.id]
                        }
                        total={dataStats?.counts.invites?.[campaign.id]}
                      />
                    ),
                    link,
                    "element",
                    TableBodyCell
                  ),
                  createTableCell(
                    campaign.execution === ExecutionKeys.DR ? (
                      "-"
                    ) : (
                      <InteractionCompareLabel
                        isLoading={isLoading}
                        count={dataStats?.counts.replies?.[campaign.id]}
                        total={dataStats?.counts.messages?.[campaign.id]}
                      />
                    ),
                    link,
                    "element",
                    TableBodyCell
                  ),

                  // createTableCell(campaign.id, link, "default", TableBodyCell),
                ],
              };
            })
          : []
      }
      count={data?.count || 0}
      // INITIAL_PAGE starts at 1, but Pagination starts at 0
      page={(data?.current || page) - 1}
      setPage={setPage}
      isFetching={isFetching}
      actionPropsIfEmpty={{
        text: "New campaign",
        onClick: createCampaign,
      }}
      rowsPerPage={rowsPerPage}
    />
  );
};

export default CampaignListTable;
