import { useCallback } from "react";
import { IPlaceholderHighlighted } from "modules/Placeholder/models";
import { useStyles } from "../styles";

export const useHighlighting = () => {
  const classes = useStyles();

  const highlightPlaceholders = useCallback(
    (text: string) => {
      let result = "";
      let lastIndex = 0;

      const regex = /({%\s*if.*?%}|{%\s*else\s*%}|{%\s*endif\s*%}|{{.*?}})/g;
      let match;

      while ((match = regex.exec(text)) !== null) {
        const matchedText = match[0];
        const start = match.index;

        result += text.slice(lastIndex, start);

        if (matchedText.startsWith("{%")) {
          // If-else statement part
          result += `<span class="${classes.highlightedIfElse}" data-placeholder="${matchedText}">${matchedText}</span>`;
        } else {
          // Regular placeholder
          const placeholderContent = matchedText.slice(2, -2).trim();
          const replacementValue =
            IPlaceholderHighlighted[
              matchedText as keyof typeof IPlaceholderHighlighted
            ];
          const displayText = replacementValue || placeholderContent;

          const formattedDisplayText =
            displayText.startsWith("{{") && displayText.endsWith("}}")
              ? displayText
              : `{{${displayText}}}`;

          result += `<span class="${classes.highlightedText}" data-original="${matchedText}">${formattedDisplayText}</span>`;
        }

        lastIndex = start + matchedText.length;
      }

      result += text.slice(lastIndex);
      return result;
    },
    [classes.highlightedText, classes.highlightedIfElse]
  );

  return { highlightPlaceholders };
};
