import AddIcon from "@mui/icons-material/Add";
import LogoutIcon from "@mui/icons-material/Logout";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Theme, useMediaQuery } from "@mui/material";
import ConfigConstant from "core/constants/ConfigConstant";
import { GlobalContext } from "core/context";
import { GlobalActionType } from "core/reducers";
import RouterConstants from "core/routes/constants";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { get } from "lodash";
import { ActionContext } from "modules/Action/context";
import { DraftActionActionType } from "modules/Action/reducers";
import AddCampaignSequence from "modules/Campaign/components/AddCampaignSequence";
import CampaignHeader from "modules/Campaign/components/CampaignHeader";
import CampaignIntegrations from "modules/Campaign/components/CampaignIntegrations";
import CampaignInteractions from "modules/Campaign/components/CampaignInteractions";
import CampaignReports from "modules/Campaign/components/CampaignReports";
import CampaignSequences from "modules/Campaign/components/CampaignSequences";
import CampaignSettings from "modules/Campaign/components/CampaignSettings";
import CampaignStatusField from "modules/Campaign/components/CampaignSettings/components/CampaignStatusField";
import CreateCampaignSearch from "modules/Campaign/components/CreateCampaignSearch";
import ExportCampaignButton from "modules/Campaign/components/ExportCampaignButton";
import MoveSelectedPeopleToCampaign from "modules/Campaign/components/MoveSelectedPeopleToCampaign";
import { CampaignDetailTabs, CampaignQuery } from "modules/Campaign/models";
import CampaignService from "modules/Campaign/services";
import { InteractionCategories } from "modules/Interaction/models";
import InteractionService from "modules/Interaction/services";
import React, { useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import Button from "ui-kit/atoms/Button";
import CardTabs, { createTabs } from "ui-kit/components/CardTabs";
import NotFound from "ui-kit/components/NotFound";

type ParamTypes = {
  campaignId: string;
  tabName: CampaignDetailTabs;
};

const CampaignDetail = (): React.ReactElement | null => {
  const params = useParams<ParamTypes>();
  const campaignId: number = +params.campaignId;
  const tabName = params.tabName;
  const queryClient = useQueryClient();
  const desktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  const {
    dispatch: dispatchSelect,
    global: { selected },
  } = React.useContext(GlobalContext);

  const { dispatch } = React.useContext(ActionContext);

  const [openSearchDialog, toggleSearchDialog] = React.useState(false);
  const [openMoveCampaignDialog, toggleMoveCampaignDialog] =
    React.useState(false);
  const [openSequenceDialog, toggleSequenceDialog] = React.useState(false);

  const { data, error, isLoading } = useQuery(
    [CampaignQuery.campaign, campaignId],
    async () => {
      try {
        const response = await CampaignService.fetchCampaign(campaignId);
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    { refetchOnMount: false, refetchOnWindowFocus: false }
  );

  const fetchSequences = async () => {
    try {
      const { data } = await CampaignService.fetchCampaignSequences(
        campaignId,
        ConfigConstant.INITIAL_PAGE
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data: dataCampaignSequences } = useQuery(
    [CampaignQuery.sequences, campaignId],
    () => fetchSequences(),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const CAMPAIGN_TABS = useMemo(
    () => [
      createTabs(CampaignDetailTabs.audience, CampaignInteractions),
      createTabs(
        CampaignDetailTabs.sequences,
        CampaignSequences,
        // If undefined, 0 or 1 = don't show the number
        dataCampaignSequences
          ? dataCampaignSequences.count <= 1
            ? undefined
            : dataCampaignSequences?.count
          : undefined
      ),
      createTabs(CampaignDetailTabs.reports, CampaignReports),
      createTabs(CampaignDetailTabs.settings, CampaignSettings),
      createTabs(CampaignDetailTabs.integrations, CampaignIntegrations),
    ],
    [dataCampaignSequences]
  );

  const handleAddProspects = () => {
    toggleSearchDialog(true);
  };

  const handleResetSelected = () => {
    dispatchSelect({
      type: GlobalActionType.SET_GLOBAL,
      payload: { selected: [] },
    });
  };

  // Set current campaign for preview on actions
  React.useEffect(() => {
    dispatch({
      type: DraftActionActionType.SET_DRAFT_ACTION,
      payload: { campaignId },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSuccess = () => {
    queryClient.invalidateQueries([CampaignQuery.interactions, campaignId]);
    snackbarHandler.success("Successfully stopped in campaign!");
  };

  const mutateRemovePersonFromCampaign = useMutation(
    (persons: number[]) =>
      CampaignService.deleteCampaignPersons({
        persons,
        campaign: campaignId,
      }),
    {
      onSuccess: () => {
        handleOnSuccess();
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const mutateRemoveSearchResultFromCampaign = useMutation(
    (search_results: number[]) =>
      CampaignService.deleteCampaignSearchResults({
        search_results,
        campaign: campaignId,
      }),
    {
      onSuccess: () => {
        handleOnSuccess();
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const mutateStopInteraction = useMutation(
    (contactId: number) =>
      InteractionService.createInteraction({
        category: InteractionCategories.sequence_stopped,
        contact: contactId,
      }),
    {
      onSuccess: () => {
        handleOnSuccess();
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const handleStop = (contactIds: number[]) => {
    if (!contactIds.length) {
      return;
    }

    contactIds.forEach((contactId) => {
      mutateStopInteraction.mutate(contactId);
    });
  };

  const handleRemove = (personIds: number[], searchResultIds: number[]) => {
    if (!!personIds.length) {
      mutateRemovePersonFromCampaign.mutate(personIds);
    }

    if (!!searchResultIds.length) {
      mutateRemoveSearchResultFromCampaign.mutate(searchResultIds);
    }
  };

  const handleFormat = () => {
    const personIds: number[] = [];
    const searchResultIds: number[] = [];
    const contactIds: number[] = [];

    selected.forEach((item) => {
      const contactId = get(item, "meta.contact");
      if (contactId) {
        return contactIds.push(Number(contactId));
      }

      const personId = get(item, "meta.person");
      if (personId) {
        return personIds.push(Number(personId));
      }

      const searchResultId = get(item, "meta.searchResult");
      if (searchResultId) {
        return searchResultIds.push(Number(searchResultId));
      }
    });

    return {
      personIds,
      searchResultIds,
      contactIds,
      count: personIds.length + searchResultIds.length + contactIds.length,
    };
  };

  const handleStopCampaign = (checkConfirm: boolean) => {
    const result = handleFormat();
    const { personIds, searchResultIds, contactIds, count } = result;

    if (
      checkConfirm &&
      !window.confirm(
        `Are you sure you want to stop campaign for ${count} people?`
      )
    ) {
      return;
    }

    handleStop(contactIds);

    handleRemove(personIds, searchResultIds);

    handleResetSelected();
  };

  const handleMoveToCampaign = () => {
    toggleMoveCampaignDialog(true);
  };

  if (error) {
    return <NotFound label="campaign" link={RouterConstants.CAMPAIGN.ALL} />;
  }

  return (
    <>
      <CampaignHeader
        campaignId={campaignId}
        campaignData={data}
        customAction={
          <>
            {!!desktop && (
              <CampaignStatusField
                campaignId={campaignId}
                execution={data?.execution}
              />
            )}

            {tabName === CampaignDetailTabs.audience && (
              <Button
                variant="outlined"
                color="inherit"
                size="small"
                type="button"
                startIcon={<AddIcon />}
                onClick={handleAddProspects}
              >
                Add prospects
              </Button>
            )}

            {tabName === CampaignDetailTabs.audience && (
              <ExportCampaignButton campaignId={campaignId} name={data?.name} />
            )}
          </>
        }
        selectedActions={
          tabName === CampaignDetailTabs.audience ? (
            <>
              <Button
                variant="outlined"
                color="inherit"
                size="small"
                type="button"
                startIcon={<LogoutIcon />}
                onClick={handleMoveToCampaign}
              >
                Move to another campaign
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                size="small"
                type="button"
                startIcon={<NotInterestedIcon />}
                onClick={() => handleStopCampaign(true)}
              >
                Stop campaign
              </Button>
            </>
          ) : undefined
        }
        disableEditIcon
        disableBodyComponent
        hiddenDivider
        filterProps={tabName === CampaignDetailTabs.audience}
      />
      <CardTabs
        tabs={CAMPAIGN_TABS}
        isLoading={isLoading}
        campaignName={data?.name}
        campaignData={data}
        campaignId={campaignId}
        getBaseUrl={(tabName: string) =>
          RouterConstants.CAMPAIGN.detail(campaignId, tabName)
        }
        showBadge
      />

      {tabName === CampaignDetailTabs.audience && (
        <CreateCampaignSearch
          open={openSearchDialog}
          toggleOpen={toggleSearchDialog}
          campaignId={campaignId}
        />
      )}

      {tabName === CampaignDetailTabs.audience && (
        <MoveSelectedPeopleToCampaign
          open={openMoveCampaignDialog}
          toggleOpen={toggleMoveCampaignDialog}
        />
      )}

      {tabName === CampaignDetailTabs.sequences && (
        <AddCampaignSequence
          campaignId={campaignId}
          open={openSequenceDialog}
          toggleOpen={toggleSequenceDialog}
        />
      )}
    </>
  );
};

export default CampaignDetail;
