import { Typography, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import { Theme } from "@mui/material/styles";
import { makeStyles, useTheme } from "@mui/styles";
import ConfigConstant from "core/constants/ConfigConstant";
import AutocompleteMultipleFilter from "core/filters/atoms/AutocompleteMultipleFilter";
import ButtonGroupFilter from "core/filters/atoms/ButtonGroupFilter";
import KeywordFilter from "core/filters/atoms/KeywordFilter";
import RouterUtils from "core/routes/utils";
import { createTableCell } from "core/utils/tableHandler";
import { AccountContext } from "modules/Account/context";
import { CampaignQuery, ICampaignParams } from "modules/Campaign/models";
import CampaignService from "modules/Campaign/services";
import InteractionContainerList from "modules/Interaction/components/InteractionContainerList";
import InteractionSearchResultView from "modules/Interaction/components/InteractionSearchResultView";
import InteractionStatusActions from "modules/Interaction/components/InteractionStatusActions";
import {
  INTERACTION_CONNECTION_FILTER_OPTIONS,
  INTERACTION_STATUS_FILTER_OPTIONS,
} from "modules/Interaction/constants";
import {
  IInteraction,
  InteractionFilters,
  InteractionViewVariant,
} from "modules/Interaction/models";
import { getInteractionStatusChipData } from "modules/Interaction/utils";
import ConnectionChip from "modules/Person/components/ConnectionChip";
import InteractionPerson from "modules/Person/components/InteractionPerson";
import SearchLoader from "modules/Search/components/SearchLoader";
import { SearchStatusMatchToVariant } from "modules/Search/models";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { Chip } from "ui-kit/atoms/Chip";
import FilterButton from "ui-kit/components/FilterButton";
import Loader from "ui-kit/components/Loader";
import Table, { TableVariant } from "ui-kit/components/Table";
import TableBodyCell from "ui-kit/components/TableBodyCell";
import TableToolbar from "ui-kit/components/TableToolbar";

const rowsPerPage = ConfigConstant.PAGE_SIZE.MEDIUM;
const heads = [
  {
    id: "name",
    percentage: true,
    width: 100,
    label: "Name",
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    display: "flex",
    marginBottom: theme.spacing(-theme.app.constants.contentBottom),
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    borderTop: `1px solid ${theme.app.palette.shadow.primary}`,
    borderRight: `1px solid ${theme.app.palette.shadow.primary}`,
    borderLeft: `1px solid ${theme.app.palette.shadow.primary}`,
    overflow: "hidden",
    height: theme.app.constants.campaign.interactions.boxHeight,
  },
  inbox: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${theme.app.constants.campaign.interactions.tableWidth}px)`,
    },
  },
  table: {
    borderRight: `1px solid ${theme.app.palette.shadow.secondary}`,
    width: theme.app.constants.campaign.interactions.tableWidth,
  },
}));

const CampaignInteractions = ({
  campaignId,
}: ICampaignParams): React.ReactElement => {
  const classes = useStyles();
  const theme: Theme = useTheme();
  const desktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const location = useLocation();
  const {
    account: { id: accountId },
  } = useContext(AccountContext);

  // State
  const [page, setPage] = useState<number>(ConfigConstant.INITIAL_PAGE);
  const [selectedPersonId, setSelectedPersonId] = useState<number>(0);
  const [selectedInteractionId, setSelectedInteractionId] = useState<number>(0);
  const [selectedInteraction, setSelectedInteraction] = useState<
    IInteraction | undefined
  >(undefined);
  const [interactionId, setInteractionId] = useState<number | undefined>(
    undefined
  );

  // Query params
  const { search, ordering } = RouterUtils.getQueryParams(location);
  const { status, connection } = RouterUtils.parseQueryParams(location);
  const [statusQuery, setStatusQuery] = useState(
    RouterUtils.generateFilterOptionQuery(
      status,
      INTERACTION_STATUS_FILTER_OPTIONS
    )
  );
  const [connectionQuery, setConnectionQuery] = useState(
    RouterUtils.generateFilterOptionQuery(
      connection,
      INTERACTION_CONNECTION_FILTER_OPTIONS
    )
  );

  // Effects for updating queries
  useEffect(() => {
    setStatusQuery(
      RouterUtils.generateFilterOptionQuery(
        status,
        INTERACTION_STATUS_FILTER_OPTIONS
      )
    );
  }, [status]);

  useEffect(() => {
    setConnectionQuery(
      RouterUtils.generateFilterOptionQuery(
        connection,
        INTERACTION_CONNECTION_FILTER_OPTIONS
      )
    );
  }, [connection]);

  // Fetch campaign interactions
  const fetchCampaignInteractions = async (p: number) => {
    try {
      const { data } = await CampaignService.fetchCampaignInteractions(
        campaignId,
        p,
        rowsPerPage,
        search,
        statusQuery,
        connectionQuery,
        ordering
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data, isFetching, isLoading } = useQuery(
    [
      CampaignQuery.interactions,
      campaignId,
      page,
      search,
      statusQuery,
      connectionQuery,
      ordering,
    ],
    () => fetchCampaignInteractions(page),
    { keepPreviousData: true }
  );

  // Fetch campaign searches
  const { data: campaignSearches } = useQuery(
    [CampaignQuery.searches, campaignId],
    () => CampaignService.fetchCampaignSearches(campaignId),
    {
      enabled: !data || data.count === 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const isOngoingSearch = useMemo(() => {
    if (
      !campaignSearches ||
      !campaignSearches.data ||
      !campaignSearches.data.results
    )
      return false;
    return campaignSearches.data.results.some(({ search }) =>
      [
        SearchStatusMatchToVariant.queue,
        SearchStatusMatchToVariant.processing,
      ].includes(search.status)
    );
  }, [campaignSearches]);

  // Handlers
  const handleInteractionClick = (
    newInteraction: IInteraction | undefined,
    activateInteractionId: boolean
  ) => {
    if (!newInteraction) return;

    if (activateInteractionId) {
      setInteractionId(newInteraction.id);
    }

    setSelectedInteractionId(newInteraction.id);

    const newPersonId = newInteraction?.person?.id;
    if (newPersonId) {
      setSelectedInteraction(undefined);
      setSelectedPersonId(newPersonId);
    } else {
      setSelectedPersonId(0);
      setSelectedInteraction(newInteraction);
    }
  };

  const handleBacklink = () => setInteractionId(undefined);

  // Effects
  useEffect(() => {
    if (data?.results?.length) {
      handleInteractionClick(data.results[0], false);
    }
  }, [data?.results]);

  // Render functions
  const renderTable = () => (
    <div className={classes.table}>
      <Table
        heads={heads}
        disableDivider
        rows={
          data?.results?.map((interaction) => ({
            name: interaction.id.toString(),
            meta: {
              person: interaction.person?.id?.toString(),
              searchResult: interaction.search_result?.id?.toString(),
              contact: interaction.contact?.id?.toString(),
            },
            data: [
              createTableCell(
                <InteractionPerson interaction={interaction} />,
                () => handleInteractionClick(interaction, true),
                "element",
                TableBodyCell
              ),
              createTableCell(
                <Chip
                  variant="outlined"
                  size="small"
                  {...getInteractionStatusChipData(interaction)}
                  icon={undefined}
                />,
                () => handleInteractionClick(interaction, true),
                "element",
                TableBodyCell
              ),
              createTableCell(
                <ConnectionChip
                  personId={interaction.person?.id}
                  accountId={accountId}
                  active_connection={interaction.contact?.active_connection}
                />,
                () => handleInteractionClick(interaction, true),
                desktop ? "element" : "hidden",
                TableBodyCell
              ),
              createTableCell(
                <InteractionStatusActions
                  contactId={interaction.contact?.id}
                  person={interaction.person}
                  searchResult={interaction.search_result}
                  campaignId={campaignId}
                  campaignName={interaction.campaign?.name}
                  category={interaction.category}
                />,
                null,
                "action",
                TableBodyCell
              ),
            ],
          })) || []
        }
        count={data?.count || 0}
        page={(data?.current || page) - 1}
        setPage={setPage}
        isFetching={isFetching}
        isLoading={isLoading}
        defaultOrderBy={ordering || "-platform_created_at"}
        rowsPerPage={rowsPerPage}
        selectedRowName={String(selectedInteractionId)}
        definedSplitTableHeight={
          theme.app.constants.campaign.interactions.tableHeight
        }
        variant={[TableVariant.split, TableVariant.checkable]}
      />
    </div>
  );

  const renderInbox = () => (
    <div className={classes.inbox}>
      {selectedPersonId ? (
        <InteractionContainerList
          accountId={accountId}
          personId={selectedPersonId}
          variant={InteractionViewVariant.campaign}
          handleBacklink={handleBacklink}
        />
      ) : selectedInteraction ? (
        <InteractionSearchResultView
          accountId={accountId}
          interaction={selectedInteraction}
          handleBacklink={handleBacklink}
        />
      ) : null}
    </div>
  );

  const renderContent = () => {
    if (isLoading) {
      return <Loader minHeight={400} />;
    }

    if (!data || data.count === 0) {
      if (isOngoingSearch && campaignSearches) {
        return campaignSearches.data.results
          .filter(({ search }) =>
            [
              SearchStatusMatchToVariant.queue,
              SearchStatusMatchToVariant.processing,
            ].includes(search.status)
          )
          .map(({ search }) => (
            <SearchLoader
              key={search.id}
              search={search}
              campaignId={campaignId}
              isCampaign={true}
            />
          ));
      }
      return (
        <Typography variant="body2" mt={2}>
          No prospects added to this campaign.
        </Typography>
      );
    }

    return (
      <div className={classes.content}>
        {desktop ? (
          <>
            {renderTable()}
            {renderInbox()}
          </>
        ) : interactionId ? (
          renderInbox()
        ) : (
          renderTable()
        )}
      </div>
    );
  };

  return (
    <Box>
      <TableToolbar
        title="Inbox"
        numSelected={0}
        disableDivider
        filters={
          <>
            <ButtonGroupFilter
              id="status"
              options={[
                { id: InteractionFilters.all, name: "All", query: {} },
                ...INTERACTION_STATUS_FILTER_OPTIONS,
              ]}
            />
            <KeywordFilter />
            <FilterButton label="Connection" id="connection">
              <AutocompleteMultipleFilter
                id="connection"
                options={INTERACTION_CONNECTION_FILTER_OPTIONS}
                label="Connection"
              />
            </FilterButton>
          </>
        }
      />
      {renderContent()}
    </Box>
  );
};

export default CampaignInteractions;
