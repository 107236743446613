import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js";
import { useQuery } from "react-query";
import Chartjs from "core/utils/chartHandler";
import { formatRangeObjectToArray } from "core/utils/commonHandler";
import { AccountContext } from "modules/Account/context";
import {
  IRestriction,
  LimitActionDefaultValues,
  LimitActionNames,
} from "modules/Report/models";
import {
  getLimitDatasetData,
  getTodayRangeQuery,
  LIMITS_ARR,
} from "modules/Report/utils";
import InteractionService from "modules/Interaction/services";
import { IAccountRestriction } from "modules/Account/models";

interface LimitPieChartProps {
  restrictions: IAccountRestriction | undefined;
  restriction: IRestriction | undefined;
}

const pieColors = [...LIMITS_ARR.map(({ color }) => color), "#e2e8f0"];
const total = pieColors.length - 1;
const dataPadding = { weight: 0.6 };

const LimitPieChart = ({
  restrictions,
  restriction,
}: LimitPieChartProps): React.ReactElement => {
  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const query = getTodayRangeQuery();
  const chartContainer = useRef<HTMLCanvasElement | null>(null);
  const [chartInstance, setChartInstance] = React.useState<null | Chart>(null);

  const fetchDataInvitations = async () => {
    try {
      const { data } = await InteractionService.fetchRequestCount(
        accountId,
        query
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data: dataInvitations } = useQuery(
    ["limits", accountId, query, LimitActionNames.invitation],
    () => fetchDataInvitations(),
    {
      keepPreviousData: true,
      staleTime: 60 * 1000, // 1 minute
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const fetchDataProfiles = async () => {
    try {
      const { data } = await InteractionService.fetchProfilesCount(
        accountId,
        query
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data: dataProfiles } = useQuery(
    ["limits", accountId, query, LimitActionNames.profile],
    () => fetchDataProfiles(),
    {
      keepPreviousData: true,
      staleTime: 60 * 1000, // 1 minute
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const fetchDataMessages = async () => {
    try {
      const { data } = await InteractionService.fetchMessagedCount(
        accountId,
        query
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data: dataMessages } = useQuery(
    ["limits", accountId, query, LimitActionNames.message],
    () => fetchDataMessages(),
    {
      keepPreviousData: true,
      staleTime: 60 * 1000, // 1 minute
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const newChartInstance = new Chartjs(chartContainer.current, {
        type: "doughnut",
        data: {
          labels: [...LIMITS_ARR.map(({ label }) => label), "Remaining"],
          datasets: [
            {
              label: "",
              data: [],
              fill: false,
            },
          ],
        },
        options: {
          cutoutPercentage: 25,
          responsive: true,
          legend: {
            labels: {
              boxWidth: 0,
            },
            display: false,
          },
          maintainAspectRatio: false,
          tooltips: {
            displayColors: false,
            backgroundColor: "white",
            bodyFontColor: "#1A1F36",
            bodyFontSize: 11,
            bodyFontStyle: "bold",
            borderColor: "rgba(0, 0, 0, 0.2)",
            borderWidth: 1,
          },
        },
      });
      setChartInstance(newChartInstance);
    }
  }, [chartContainer]);

  useEffect(() => {
    if (
      chartInstance &&
      chartInstance.data &&
      chartInstance.data.datasets &&
      chartInstance.data.datasets[0] &&
      dataInvitations &&
      dataMessages &&
      dataProfiles &&
      restrictions
    ) {
      const datasets = [
        {
          data: getLimitDatasetData({
            index: 0,
            count: dataInvitations.count,
            range: formatRangeObjectToArray(
              restrictions.connection_requests_daily_range
            ),
            restriction: restriction?.connection_requests,
            total,
          }),
          backgroundColor: pieColors,
        },
        dataPadding,
        {
          data: getLimitDatasetData({
            index: 1,
            count: dataMessages.count,
            range: formatRangeObjectToArray(restrictions.follow_up_messages),
            restriction: restriction?.follow_up_messages,
            total,
          }),
          backgroundColor: pieColors,
        },
        dataPadding,
        {
          data: getLimitDatasetData({
            index: 2,
            count: dataProfiles.count,
            range: [dataProfiles.count, dataProfiles.count],
            restriction: Number(LimitActionDefaultValues.profile),
            total,
          }),
          backgroundColor: pieColors,
        },
      ];
      chartInstance.data.datasets = datasets;
      chartInstance.update();
    }
  }, [
    chartInstance,
    dataInvitations,
    dataMessages,
    dataProfiles,
    restrictions,
    restriction,
    accountId,
  ]);

  return <canvas ref={chartContainer} />;
};

export default LimitPieChart;
