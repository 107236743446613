import {
  Box,
  Card,
  CircularProgress,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { AccountContext } from "modules/Account/context";
import CampaignService from "modules/Campaign/services";
import { getFilledPercentage } from "modules/Report/utils";
import { SEARCH_STRATEGY_MAX_RESULTS } from "modules/Search/constants";
import {
  ISearch,
  SearchMatchVariantStrategy,
  SearchStatusMatchToVariant,
} from "modules/Search/models";
import SearchUtils from "modules/Search/utils";
import React, { useContext, useMemo } from "react";
import { useQuery } from "react-query";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    display: "flex",
    alignItems: "center",
  },
  progressBar: {
    maxWidth: 430,
    borderRadius: 50,
    height: theme.spacing(2),
    backgroundColor: theme.palette.action.focus,
    width: "100%",
    overflow: "hidden",
  },
  filledBar: {
    backgroundColor: theme.palette.secondary.main,
    height: theme.spacing(2),
    borderRadius: 50,
  },
  subtitle: {
    color: theme.app.palette.action.main,
    fontSize: 13,
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  // Add new styles for the pulsating effect
  pulsate: {
    animation: `$pulsateAnimation 1.5s ease-in-out infinite`,
    backgroundColor: theme.palette.action.hover,
    height: "100%",
    width: "100%",
  },
  // Define the keyframes for the pulsating animation
  "@keyframes pulsateAnimation": {
    "0%": { opacity: 0 },
    "50%": { opacity: 1 },
    "100%": { opacity: 0 },
  },
}));

interface SearchLoaderProps {
  search: ISearch;
  campaignId: number;
  isCampaign?: boolean;
}

const SearchLoader = ({
  search,
  campaignId,
  isCampaign = false,
}: SearchLoaderProps): React.ReactElement => {
  const classes = useStyles();

  const {
    account: { person: isInitialSync },
  } = useContext(AccountContext);

  const fetchInteractions = async () => {
    try {
      const { data } =
        await CampaignService.fetchCampaignInteractionsCountPerSearch(
          campaignId,
          search.id
        );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data } = useQuery(
    ["search-result-count", campaignId, { searchId: search.id }],
    () => fetchInteractions(),
    {
      keepPreviousData: true,
      enabled: !!campaignId,
      // Refetch every 10 seconds as loader only runs for processing search
      refetchInterval: 10000,
    }
  );

  const count = useMemo(() => data?.count || 0, [data?.count]);
  const totalMax = useMemo(
    () => SEARCH_STRATEGY_MAX_RESULTS[search.strategy],
    [search.strategy]
  );
  const maxCount = useMemo(
    () =>
      SearchUtils.getMaxAvailableSearchResultCount(
        totalMax,
        search.strategy_data.total
      ),
    [totalMax, search.strategy_data.total]
  );

  const searchInQueue = useMemo(
    () => search.status === SearchStatusMatchToVariant.queue,
    [search.status]
  );
  const isEmptyProgress = useMemo(() => count === 0, [count]);

  const subtitle = useMemo(() => {
    let subString = isCampaign
      ? "It takes up to 5 minutes for contacts to show below..."
      : "It takes up to 5 mins for leads to show... You can go to the next step.";
    if (searchInQueue) {
      return isInitialSync
        ? subString
        : "Please finish connecting your LinkedIn account to start your search.";
    }
    return `${count.toLocaleString()} of ${maxCount.toLocaleString()} prospects are ready...`;
  }, [searchInQueue, isInitialSync, count, maxCount, isCampaign]);

  return (
    <>
      <Card sx={{ mb: 4 }}>
        <ListItem>
          <ListItemIcon>
            <CircularProgress color="secondary" size={32} />
          </ListItemIcon>
          <ListItemText
            sx={{ maxWidth: 340, mr: 3 }}
            primaryTypographyProps={{
              sx: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }}
            primary={search.name}
            secondary={SearchMatchVariantStrategy[search.strategy]}
          />
          <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
            <Typography variant="body2" color="dimmed" mb={0.75}>
              {subtitle}
            </Typography>
            <div className={classes.progressBar}>
              {isEmptyProgress ? (
                <div className={classes.pulsate} />
              ) : (
                <div
                  className={classes.filledBar}
                  style={{
                    width: getFilledPercentage(count, maxCount),
                  }}
                />
              )}
            </div>
          </Box>
        </ListItem>
      </Card>
      {!isEmptyProgress && <Divider light sx={{ mb: 3 }} />}
    </>
  );
};

export default SearchLoader;
