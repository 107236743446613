import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import StrategyConstant from "core/constants/StrategyConstant";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import ConfigConstant from "core/constants/ConfigConstant";
import { snackbarHandler } from "core/utils/snackbarHandler";
import InteractionService from "modules/Interaction/services";
import {
  IDirectActionFormValues,
  IDirectActionFormInput,
  InteractionCategories,
  InteractionsQuery,
} from "modules/Interaction/models";
import { DirectActionStrategy } from "modules/Action/models";
import { ActionContext } from "modules/Action/context";
import { DraftActionActionType } from "modules/Action/reducers";
import ConnectionModal from "../ConnectionModal/component";
import PlaceholderController from "modules/Placeholder/components/PlaceholderController";

const defaultValues = {
  strategy_data: {
    template: "",
  },
};

const resetValues = {
  strategy_data: {
    template: null,
  },
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: 2,
    position: "sticky",
    bottom: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(2, 4),
    borderTop: `1px solid ${theme.app.palette.shadow.secondary}`,
    backgroundColor: theme.palette.common.white,
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inbox: {
    height: "100%",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "end",
    },
    [theme.breakpoints.up("md")]: {
      alignItems: "center",
      justifyContent: "space-between",
      marginLeft: theme.spacing(12),
    },
  },
  speedDial: {
    position: "absolute",
    left: theme.spacing(1),
    bottom: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

interface CreateDirectActionProps {
  personId: number;
  accountId: number;
  open: boolean;
  setOpen: (v: boolean) => void;
}

// StrategyConstant without sendMessage(default)
const StrategyConstantExtra = [
  StrategyConstant.STRATEGY_OBJ.connection_request,
  StrategyConstant.STRATEGY_OBJ.profile_view,
];

const CreateDirectAction = ({
  personId,
  accountId,
  open,
  setOpen,
}: CreateDirectActionProps): React.ReactElement => {
  const [openStrategy, setOpenStrategy] = React.useState(
    DirectActionStrategy[
      "inevitable.strategy.direct_action.LinkedInMessageStrategy"
    ]
  );
  const classes = useStyles();

  const {
    dispatch,
    draftAction: { personId: actionPerson },
  } = React.useContext(ActionContext);

  React.useEffect(() => {
    if (personId !== actionPerson) {
      dispatch({
        type: DraftActionActionType.SET_DRAFT_ACTION,
        payload: { personId },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personId]);

  const { control, errors, handleSubmit, setError, reset } =
    useForm<IDirectActionFormValues>({
      defaultValues,
    });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const queryClient = useQueryClient();
  const mutateCreateDirectAction = useMutation(
    (data: IDirectActionFormValues) =>
      InteractionService.createDirectAction(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["direct-actions"]);
        queryClient.invalidateQueries([InteractionsQuery.interactions_person]);
        snackbarHandler.success("Action scheduled to be send.");
        reset(resetValues);
        handleClose();
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response, setError);
      },
    }
  );

  const onSubmit = (data: IDirectActionFormInput) => {
    const newData = {
      person: personId,
      account: accountId,
      execution_strategy: openStrategy,
      ...data,
    };

    mutateCreateDirectAction.mutate(newData);
  };

  return (
    <div className={classes.root}>
      <SpeedDial
        ariaLabel="SpeedDial uncontrolled open example"
        className={classes.speedDial}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        FabProps={{
          color: "primary",
          size: "small",
        }}
      >
        {StrategyConstantExtra.map(
          ({ id, label, icon: IconComponent, color }) => (
            <SpeedDialAction
              key={id}
              icon={<IconComponent sx={{ color }} />}
              tooltipOpen
              tooltipTitle={label.account_is_actor}
              tooltipPlacement="right"
              onClick={() => {
                handleClose();
                if (id === InteractionCategories.profile_view) {
                  onSubmit({
                    execution_strategy:
                      DirectActionStrategy[
                        "inevitable.strategy.direct_action.LinkedInViewProfileStrategy"
                      ],
                  });
                }
                if (id === InteractionCategories.connection_request) {
                  setOpenStrategy(
                    DirectActionStrategy[
                      "inevitable.strategy.direct_action.LinkedInConnectionRequestStrategy"
                    ]
                  );
                }
              }}
            />
          )
        )}
      </SpeedDial>
      <form
        className={classes.inbox}
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <PlaceholderController
          control={control}
          name="strategy_data.template"
          placeholder="Message"
          error={errors.strategy_data?.template}
          multiline={
            openStrategy ===
            DirectActionStrategy[
              "inevitable.strategy.direct_action.LinkedInConnectionRequestStrategy"
            ]
          }
          rows={4}
          maxLength={ConfigConstant.CHARACTER_LIMIT.MESSAGE}
          endAdornmentSubmit
          disableCharacterCount
        />
      </form>
      <ConnectionModal
        open={openStrategy}
        setOpen={setOpenStrategy}
        handleSubmit={handleSubmit(onSubmit)}
      >
        <PlaceholderController
          control={control}
          name="strategy_data.template"
          placeholder="Message"
          error={errors.strategy_data?.template}
          multiline={true}
          rows={4}
          maxLength={ConfigConstant.CHARACTER_LIMIT.CONNECTION_REQUEST}
        />
      </ConnectionModal>
    </div>
  );
};

export default CreateDirectAction;
