import React from "react";
import { Dayjs } from "dayjs";
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MuiDatePicker from "@mui/lab/DatePicker";
import { TextFieldProps } from "@mui/material/TextField";
import { formatDateToStr, formatStrToDate } from "core/utils/dateHandler";
import MuiTextField from "./TextField";
import { useTheme } from "@mui/styles";
import DateConstant from "core/constants/DateConstant";

export interface IPickerProps {
  name: string;
  value: string;
  label: string;
  onChange: (date: string | null) => void;
  disabled?: boolean;
  InputProps?: TextFieldProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ElementProps?: any;
}

const DatePicker = React.forwardRef<HTMLDivElement, IPickerProps>(
  function TextField(
    { value, label, onChange, InputProps, ElementProps, ...rest }: IPickerProps,
    ref
  ): React.ReactElement {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const onHandleChange = (date: Dayjs) => {
      onChange(formatDateToStr(date));
    };

    const handleOpen = () => {
      if (ElementProps.disabled) {
        return;
      }

      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MuiDatePicker
          {...ElementProps}
          label={label}
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          onClick={handleOpen}
          value={formatStrToDate(value)}
          onChange={onHandleChange}
          inputFormat={DateConstant.DATE_PICKER_FORMAT}
          renderInput={(params) => (
            <MuiTextField
              {...rest}
              {...params}
              {...InputProps}
              fullWidth
              inputProps={{
                ...params.inputProps,
                placeholder: "",
              }}
              sx={{
                [theme.breakpoints.up("md")]: {
                  maxWidth: theme.app.constants.inputMaxWidth,
                },
              }}
              ref={ref}
              onKeyDown={handleOpen}
              onClick={handleOpen}
            />
          )}
        />
      </LocalizationProvider>
    );
  }
);

DatePicker.defaultProps = {
  InputProps: {},
  ElementProps: {},
  disabled: false,
};

export default DatePicker;
