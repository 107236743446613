import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { DirectActionStrategy } from "modules/Action/models";
import Button from "ui-kit/atoms/Button";
import DialogHeader from "ui-kit/components/DialogHeader";
import PreviewButton from "modules/Placeholder/components/PlaceholderController/components/PreviewButton";

interface ConnectionModalProps {
  children: React.ReactElement;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSubmit: any;
  open: string;
  setOpen: (v: DirectActionStrategy) => void;
}

const ConnectionModal = ({
  children,
  handleSubmit,
  open,
  setOpen,
}: ConnectionModalProps): React.ReactElement => {
  const handleClose = () =>
    setOpen(
      DirectActionStrategy[
        "inevitable.strategy.direct_action.LinkedInMessageStrategy"
      ]
    );

  return (
    <Dialog
      open={
        open ===
        DirectActionStrategy[
          "inevitable.strategy.direct_action.LinkedInConnectionRequestStrategy"
        ]
      }
      keepMounted
      onClose={handleClose}
      maxWidth="md"
      PaperProps={{ sx: { width: "100%" } }}
    >
      <form
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
          handleClose();
        }}
      >
        <DialogHeader
          title="Send an invitation to connect"
          onHandleClose={handleClose}
          previewComponent={<PreviewButton isPreviewable />}
        />
        <DialogContent dividers sx={{ pb: 12 }}>
          {children}
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary">
            Send
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ConnectionModal;
