import { loadStripe } from "@stripe/stripe-js";
import ConfigConstant from "core/constants/ConfigConstant";
import {
  IAccountSubscription,
  SubscriptionStatus,
} from "modules/Payment/models";

// Stripe.js will not be loaded until `loadStripe` is called
const stripePromise = loadStripe(ConfigConstant.STRIPE_PUB_KEY as string);

// const hasActiveSubscription = (
//   arr: IAccountSubscription["subscriptions"] | undefined
// ): boolean => {
//   if (!arr) {
//     return false;
//   }
//   return arr.some(
//     (sub) =>
//       sub.status === SubscriptionStatus.active ||
//       sub.status === SubscriptionStatus.trialing ||
//       sub.status === SubscriptionStatus.incomplete
//   );
// };

const isUserTrialAvailable = (
  arr: IAccountSubscription[] | undefined
): boolean => {
  if (!arr) {
    return false;
  }

  // If there is more than one subscription, it means the user has already used the trial
  if (arr.length > 1) {
    return false;
  }

  return !arr.some((sub) => sub.subscriptions.length > 0);
};

const isUserTrialActive = (
  arr: IAccountSubscription["subscriptions"] | undefined
): boolean => {
  if (!arr) {
    return false;
  }

  return arr.some((sub) => sub.status === SubscriptionStatus.trialing);
};

export {
  // hasActiveSubscription,
  isUserTrialActive,
  isUserTrialAvailable,
  stripePromise,
};
