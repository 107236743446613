import { CampaignLocale } from "modules/Campaign/models";
import { DefaultPlaceholdersKeys } from "modules/Placeholder/models";

function arrayMove(
  arr: (undefined | [])[],
  oldIndex: number,
  newIndex: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any[] {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    // eslint-disable-next-line no-plusplus
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr.map((a, index) => ({ ...a, order_nr: index + 1 }));
}

const defaultDraftAction = {
  previewMode: false,
  previewContactName: undefined,
  campaignId: undefined,
  personId: undefined,
  dynamicPlaceholder: undefined,
  locale: CampaignLocale.en,
  placeholders: undefined,
};

// // Find unenclosed placeholders in the text
// const findUnenclosedPlaceholders = (
//   text: string,
//   placeholders: string[]
// ): string[] => {
//   const unenclosedPlaceholders: string[] = [];
//   placeholders.forEach((placeholder) => {
//     const regex = new RegExp(`\\b${placeholder}\\b(?!}})`);
//     if (regex.test(text)) {
//       unenclosedPlaceholders.push(placeholder);
//     }
//   });
//   return unenclosedPlaceholders;
// };

// // Check and confirm unenclosed placeholders in the text
// const checkAndConfirmUnenclosedPlaceholders = (template: string): boolean => {
//   const unenclosedPlaceholders = findUnenclosedPlaceholders(
//     template,
//     Object.values(DefaultPlaceholdersKeys)
//   );

//   if (unenclosedPlaceholders.length > 0) {
//     const placeholderList = unenclosedPlaceholders.join(", ");
//     const message =
//       unenclosedPlaceholders.length === 1
//         ? `The placeholder "${placeholderList}" is not properly set up and it will not be replaced in your message. Do you still want to save?`
//         : `The following placeholders are not properly set up and they will not be replaced in your message: ${placeholderList}. Do you still want to save?`;

//     return window.confirm(message);
//   }

//   return true;
// };

export { arrayMove, defaultDraftAction };
