import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Control, FieldErrors } from "react-hook-form";

export enum CountryIds {
  "al" = "al",
  "ar" = "ar",
  "am" = "am",
  "au" = "au",
  "at" = "at",
  "az" = "az",
  "bd" = "bd",
  "by" = "by",
  "be" = "be",
  "bo" = "bo",
  "ba" = "ba",
  "br" = "br",
  "bg" = "bg",
  "kh" = "kh",
  "ca" = "ca",
  "cl" = "cl",
  "cn" = "cn",
  "co" = "co",
  "cr" = "cr",
  "hr" = "hr",
  "cy" = "cy",
  "cz" = "cz",
  "dk" = "dk",
  "do" = "do",
  "ec" = "ec",
  "eg" = "eg",
  "ee" = "ee",
  "fi" = "fi",
  "fr" = "fr",
  "ge" = "ge",
  "de" = "de",
  "gr" = "gr",
  "gt" = "gt",
  "hk" = "hk",
  "hu" = "hu",
  "is" = "is",
  "in" = "in",
  "id" = "id",
  "ie" = "ie",
  "im" = "im",
  "il" = "il",
  "it" = "it",
  "jm" = "jm",
  "jp" = "jp",
  "jo" = "jo",
  "kz" = "kz",
  "ke" = "ke",
  "kw" = "kw",
  "kg" = "kg",
  "la" = "la",
  "lv" = "lv",
  "lt" = "lt",
  "lu" = "lu",
  "mk" = "mk",
  "my" = "my",
  "mt" = "mt",
  "mx" = "mx",
  "md" = "md",
  "ma" = "ma",
  "nl" = "nl",
  "nz" = "nz",
  "ng" = "ng",
  "no" = "no",
  "om" = "om",
  "pk" = "pk",
  "pa" = "pa",
  "pe" = "pe",
  "ph" = "ph",
  "pl" = "pl",
  "pt" = "pt",
  "ro" = "ro",
  "ru" = "ru",
  "sa" = "sa",
  "rs" = "rs",
  "sl" = "sl",
  "sg" = "sg",
  "sk" = "sk",
  "si" = "si",
  "za" = "za",
  "kr" = "kr",
  "es" = "es",
  "lk" = "lk",
  "se" = "se",
  "ch" = "ch",
  "tw" = "tw",
  "tj" = "tj",
  "th" = "th",
  "tn" = "tn",
  "tr" = "tr",
  "tm" = "tm",
  "ua" = "ua",
  "ae" = "ae",
  "gb" = "gb",
  "us" = "us",
  "uz" = "uz",
  "vn" = "vn",
}

export enum CountryCodes {
  "al" = "Albania",
  "ar" = "Argentina",
  "am" = "Armenia",
  "au" = "Australia",
  "at" = "Austria",
  "az" = "Azerbaijan",
  "bd" = "Bangladesh",
  "by" = "Belarus",
  "be" = "Belgium",
  "bo" = "Bolivia",
  "ba" = "Bosnia And Herzegovina",
  "br" = "Brazil",
  "bg" = "Bulgaria",
  "kh" = "Cambodia",
  "ca" = "Canada",
  "cl" = "Chile",
  "cn" = "China",
  "co" = "Colombia",
  "cr" = "Costa Rica",
  "hr" = "Croatia",
  "cy" = "Cyprus",
  "cz" = "Czech Republic",
  "dk" = "Denmark",
  "do" = "Dominican Republic",
  "ec" = "Ecuador",
  "eg" = "Egypt",
  "ee" = "Estonia",
  "fi" = "Finland",
  "fr" = "France",
  "ge" = "Georgia",
  "de" = "Germany",
  "gr" = "Greece",
  "gt" = "Guatemala",
  "hk" = "Hong Kong",
  "hu" = "Hungary",
  "is" = "Iceland",
  "in" = "India",
  "id" = "Indonesia",
  "ie" = "Ireland",
  "im" = "Isle of Man",
  "il" = "Israel",
  "it" = "Italy",
  "jm" = "Jamaica",
  "jp" = "Japan",
  "jo" = "Jordan",
  "kz" = "Kazakhstan",
  "ke" = "Kenya",
  "kw" = "Kuwait",
  "kg" = "Kyrgyzstan",
  "la" = "Laos",
  "lv" = "Latvia",
  "lt" = "Lithuania",
  "lu" = "Luxembourg",
  "mk" = "Macedonia",
  "my" = "Malaysia",
  "mt" = "Malta",
  "mx" = "Mexico",
  "md" = "Moldova",
  "ma" = "Morocco",
  "nl" = "Netherlands",
  "nz" = "New Zealand",
  "ng" = "Nigeria",
  "no" = "Norway",
  "om" = "Oman",
  "pk" = "Pakistan",
  "pa" = "Panama",
  "pe" = "Peru",
  "ph" = "Philippines",
  "pl" = "Poland",
  "pt" = "Portugal",
  "ro" = "Romania",
  "ru" = "Russia",
  "sa" = "Saudi Arabia",
  "rs" = "Serbia",
  "sl" = "Sierra Leone",
  "sg" = "Singapore",
  "sk" = "Slovak Republic",
  "si" = "Slovenia",
  "za" = "South Africa",
  "kr" = "South Korea",
  "es" = "Spain",
  "lk" = "Sri Lanka",
  "se" = "Sweden",
  "ch" = "Switzerland",
  "tw" = "Taiwan",
  "tj" = "Tajikistan",
  "th" = "Thailand",
  "tn" = "Tunisia",
  "tr" = "Turkey",
  "tm" = "Turkmenistan",
  "ua" = "Ukraine",
  "ae" = "United Arab Emirates",
  "gb" = "United Kingdom",
  "us" = "United States",
  "uz" = "Uzbekistan",
  "vn" = "Vietnam",
}

export enum Platforms {
  "linkedin" = "linkedin",
}

export enum BacklinkButtonVariant {
  "back" = "back",
  "content" = "content",
  "vertical" = "vertical",
}

export enum DeviceVariant {
  "mobile" = "mobile",
  "desktop" = "desktop",
}

export enum DeviceSize {
  xl = "xl",
  lg = "lg",
  md = "md",
  sm = "sm",
  xs = "xs",
}

export enum DateInterval {
  day = "day",
  month = "month",
}

export enum DiffInterval {
  day = "day",
  month = "month",
  hour = "hour",
}

export enum TitleDropdownVariant {
  "rename" = "rename",
  "delete" = "delete",
}

export enum TabLabels {
  HOME = "Home",
  CAMPAIGNS = "Campaigns",
  INBOX = "Inbox",
  MY_NETWORK = "My Network",
  SEARCH = "Search",
  TEMPLATES = "Templates",
  ACCOUNT_SETTINGS = "Settings",
  INTEGRATIONS = "Integrations",
  TEAM = "Team",
  USER = "User settings",
  BILLING = "Billing",
}

export enum TabIds {
  HOME = "home",
  CAMPAIGNS = "campaigns",
  INBOX = "inbox",
  MY_NETWORK = "my-network",
  SEARCH = "search",
  TEMPLATES = "templates",
  ACCOUNT_SETTINGS = "account-settings",
  INTEGRATIONS = "integrations",
  TEAM = "team",
}

export interface IBacklinkProps {
  text: string;
  link?: string;
  onClick?: () => void;
}

export interface IArrayResponse {
  count: number;
  next: string;
  previous: string;
  current: number;
  page: number;
}

export interface ICreateStepValues {
  activeStep: number;
  onHandleBack: () => void;
  onHandleNext: () => void;
  type: "button" | "submit";
}

export interface IFormProps {
  control: Control;
  errors: FieldErrors;
}

export interface IActionProps {
  text: string;
  link?: string;
  onClick?: (e?: any) => void;
  disabled?: boolean;
  hiddenStartIcon?: boolean;
  customStartIcon?: React.ReactElement;
}

export interface IAutocompleteOptionItem {
  id: number;
  label: string;
}

export type IAutocompleteOption =
  | IAutocompleteOptionItem
  | IAutocompleteOptionItem[];

export interface IOption {
  id: number | string;
  name: string;
}

export interface IFilterOption {
  id: string;
  name: string;
  query: {
    [key: string]: string | boolean | number | undefined;
  };
}

export type IFilterOptions = IFilterOption[];

export interface IProfileClicked {
  searchResultId?: number;
  contactId?: number;
  personId?: number;
  connection?: boolean;
}

export enum DefaultPlaceholdersLabels {
  first_name = "First name",
  last_name = "Last name",
  company_name = "Company name",
  job_title = "Job Title",
  job_industry = "Job Industry",
  location = "Location",
  // occupation = "Occupation",
}

export interface IOptionProps {
  label: string;
  id: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: React.FunctionComponent<any>;
  color?: string;
}

export interface IMeta {
  [key: string]: string | undefined;
}

export interface ISelectItem {
  name: string;
  meta?: IMeta | undefined;
}

export interface IGlobalContext {
  selected: ISelectItem[];
  cancelDialog: boolean;
}

export interface INewGlobalContext {
  selected?: ISelectItem[];
  cancelDialog?: boolean;
}

export interface IActionSelectorOption {
  id: string;
  primary: string;
  secondary: string;
}

export type ItemTypes = { id: number; name: string; inputValue?: string };

export interface ITitleDropdownItem {
  id: TitleDropdownVariant;
  label: string;
  icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string };
  func: (data?: any) => void;
}

export type ITitleDropdownList = ITitleDropdownItem[];

export interface IError {
  response: {
    status: number;
  };
}

export interface ILocationResponse {
  country_code: CountryIds;
  city: string;
  country_name: string;
}

export interface ICountry {
  country: CountryIds;
}
