import { lighten, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createPlaceholderName } from "modules/Placeholder/utils";
import React from "react";
import reactStringReplace from "react-string-replace";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.text.primary,
    padding: theme.spacing(0.25, 0.5),
    fontSize: 13.5,
    borderRadius: 3,
  },
  static: {
    backgroundColor: lighten(theme.palette.secondary.light, 0.7),
  },
  dynamic: {
    backgroundColor: lighten(theme.palette.warning.light, 0.7),
  },
  language: {
    backgroundColor: lighten(theme.palette.warning.light, 0.7),
  },
  weekday: {
    backgroundColor: lighten(theme.palette.secondary.light, 0.7),
  },
}));

interface PlaceholderTemplateProps {
  match: string;
}

const PlaceholderTemplate = ({
  match,
}: PlaceholderTemplateProps): React.ReactElement => {
  const classes = useStyles();
  return <span className={classes.root}>{createPlaceholderName(match)}</span>;
};

export const interpolatePlaceholders = (
  context: string | undefined
): React.ReactNode | undefined => {
  if (!context) {
    return undefined;
  }
  return reactStringReplace(context, /{{(.*?)}}/g, (match, i) => (
    <PlaceholderTemplate key={String(i)} match={match} />
  ));
};

export default PlaceholderTemplate;
