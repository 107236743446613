import React from "react";
import { Control, Controller, FieldError } from "react-hook-form";
import PlaceholderEditor from "./components/PlaceholderEditor/component";

interface PlaceholderControllerProps {
  control: Control;
  name: string;
  placeholder: string;
  error?: FieldError;
  multiline?: boolean;
  rows?: number;
  maxLength?: number;
  endAdornmentSubmit?: boolean;
  disableCharacterCount?: boolean;
}

const PlaceholderController: React.FC<PlaceholderControllerProps> = ({
  control,
  name,
  placeholder,
  error,
  multiline = false,
  rows = 1,
  maxLength,
  endAdornmentSubmit,
  disableCharacterCount = false,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ value, onChange }) => (
        <PlaceholderEditor
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          error={error?.message}
          multiline={multiline}
          rows={rows}
          maxLength={maxLength}
          endAdornmentSubmit={endAdornmentSubmit}
          disableCharacterCount={disableCharacterCount}
        />
      )}
    />
  );
};

export default PlaceholderController;
