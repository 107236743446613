import ConfigConstant from "core/constants/ConfigConstant";
import * as Yup from "yup";
import { ActionStrategy } from "../models";

export const ERROR_KEYS = {
  EXECUTION_STRATEGY: {
    REQUIRED: "Action type is required.",
  },
  TIME_DELTA: {
    REQUIRED: "Time delay is required",
  },
  STRATEGY_DATA: {
    REQUIRED: "Message content is required.",
    MAX_CONNECTION_REQUEST: `Message can contain maximum of ${ConfigConstant.CHARACTER_LIMIT.CONNECTION_REQUEST} characters.`,
    MAX_MESSAGE: `Message can contain maximum of ${ConfigConstant.CHARACTER_LIMIT.MESSAGE} characters.`,
  },
};

function actionSchema(): Yup.AnyObjectSchema {
  return Yup.object().shape({
    execution_strategy: Yup.string().required(
      ERROR_KEYS.EXECUTION_STRATEGY.REQUIRED
    ),
    strategy_data: Yup.object().when("execution_strategy", {
      is: ActionStrategy[
        "inevitable.strategy.action.LinkedInViewProfileStrategy"
      ],
      then: Yup.object().shape({
        template: Yup.string().notRequired(),
      }),
      otherwise: Yup.object().when("execution_strategy", {
        is: ActionStrategy[
          "inevitable.strategy.action.LinkedInConnectionRequestStrategy"
        ],
        then: Yup.object().shape({
          template: Yup.string()
            .notRequired()
            .max(
              ConfigConstant.CHARACTER_LIMIT.CONNECTION_REQUEST,
              ERROR_KEYS.STRATEGY_DATA.MAX_CONNECTION_REQUEST
            )
            .nullable(),
        }),
        otherwise: Yup.object().shape({
          template: Yup.string()
            .required(ERROR_KEYS.STRATEGY_DATA.REQUIRED)
            .max(
              ConfigConstant.CHARACTER_LIMIT.MESSAGE,
              ERROR_KEYS.STRATEGY_DATA.MAX_MESSAGE
            )
            .nullable(),
        }),
      }),
    }),
  });
}

const ActionValidations = { actionSchema };

export default ActionValidations;
