import React from "react";
import { SvgIconComponent } from "@mui/icons-material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ConfigConstant from "core/constants/ConfigConstant";
import {
  SearchStrategy,
  SearchTabs,
  SearchTypeFilters,
  SearchStatusFilters,
  SearchStatusMatchToVariant,
  SearchResultStatusMatchToVariant,
  SearchMatchFilterStrategy,
  SearchResultStatusFilters,
  SearchMatchFilterLabel,
  SearchStatus,
  SearchResultStatus,
  ISearchExample,
} from "modules/Search/models";
import SearchDefaultForm from "../components/SearchItem/components/SearchDefaultForm";
import SearchGoogleSheetForm from "../components/SearchItem/components/SearchGoogleSheetForm";
import SearchExistingForm from "../components/SearchItem/components/SearchExistingForm";

export interface CreateSearchInstructionProps {
  icon: string;
  name: string;
  url: string;
  examples: ISearchExample[];
}

export interface CreateSearchComponentProps {
  title: string;
  subtitle: string | React.ReactElement;
  label: string;
  subtitleTooltip: string | undefined;
}

export interface ISearchTabProps {
  variant: SearchTabs;
  strategy: SearchStrategy | undefined;
  title: string;
  icon: SvgIconComponent;
  componentProps: CreateSearchComponentProps;
  instructionProps: CreateSearchInstructionProps | undefined;
  component: React.FunctionComponent<any>;
}

const createTab = (
  variant: ISearchTabProps["variant"],
  strategy: ISearchTabProps["strategy"],
  title: ISearchTabProps["title"],
  icon: ISearchTabProps["icon"],
  componentProps: ISearchTabProps["componentProps"],
  instructionProps: ISearchTabProps["instructionProps"],
  component: ISearchTabProps["component"]
) => ({
  variant,
  strategy,
  title,
  icon,
  componentProps,
  instructionProps,
  component,
});

const createSearchComponent = (
  title: CreateSearchComponentProps["title"],
  subtitle: CreateSearchComponentProps["subtitle"],
  subtitleTooltip: CreateSearchComponentProps["subtitleTooltip"],
  label: CreateSearchComponentProps["label"]
) => ({
  title,
  subtitle,
  subtitleTooltip,
  label,
});

const createSearchInstructions = (
  icon: CreateSearchInstructionProps["icon"],
  name: CreateSearchInstructionProps["name"],
  url: CreateSearchInstructionProps["url"],
  examples: CreateSearchInstructionProps["examples"]
) => ({
  icon,
  name,
  url,
  examples,
});

const ALL_NEW_SEARCH_TABS = [
  createTab(
    SearchTabs.linkedin_search,
    SearchStrategy["inevitable.strategy.search.BasicStrategy"],
    "LinkedIn search",
    LinkedInIcon,
    createSearchComponent(
      "Find prospects",
      <div>
        Find people in{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={ConfigConstant.SEARCH.BASIC_SEARCH}
        >
          LinkedIn search
        </a>
        ,{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={ConfigConstant.SEARCH.SALES_NAV_SEARCH}
        >
          Sales Navigator
        </a>{" "}
        or in{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={ConfigConstant.SEARCH.RECRUITER_SEARCH}
        >
          Recruiter
        </a>{" "}
        and insert the URL to your search results below.
      </div>,
      "Imports up to 1000 results.",
      "Search URL"
    ),
    createSearchInstructions(
      "/linkedin.svg",
      "Linkedin",
      "https://www.linkedin.com/search/results/people/?geoUrn=%5B%22103644278%22%5D&origin=FACETED_SEARCH&sid=*~F",
      [
        {
          name: "LinkedIn Basic search",
          urls: ["https://www.linkedin.com/search/results/people/"],
        },
        {
          name: "Sales Navigator search",
          urls: [
            "https://www.linkedin.com/sales/search/people/",
            "https://www.linkedin.com/sales/lists/people/",
          ],
        },
        {
          name: "LinkedIn Recruiter search",
          urls: [
            "https://www.linkedin.com/talent/search/",
            "https://www.linkedin.com/talent/hire/",
          ],
        },
      ]
    ),
    SearchDefaultForm
  ),
  createTab(
    SearchTabs.google_sheet,
    SearchStrategy["inevitable.strategy.search.GoogleSheetsStrategy"],
    "Import Google Sheets",
    DescriptionOutlinedIcon,
    createSearchComponent(
      "Import Google Sheets",
      <div>
        Make sure every row contains a <b>profile_url</b>. Here you can view{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={ConfigConstant.SEARCH.GOOGLE_SHEET}
        >
          Example
        </a>
        .
      </div>,
      "Other values are optional and will be replaced by the values collected on LinkedIn.",
      "Enter Google Sheets URL"
    ),
    createSearchInstructions(
      "/google-sheet.svg",
      "Google Sheets URL",
      "https://docs.google.com/spreadsheets/d/1dx58L_aFJjzBqJNgXibUsLFPxVGn--wr-d828M0PwGI/edit#gid=0",
      []
    ),
    SearchGoogleSheetForm
  ),
  // createTab(
  //   SearchTabs["group_search"],
  //   SearchStrategy["inevitable.strategy.search.GroupStrategy"],
  //   "Group members",
  //   GroupsIcon,
  //   createSearchComponent(
  //     "Group members",
  //     <div>
  //       Select a group from your{" "}
  //       <a
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         href={ConfigConstant.SEARCH.GROUP_SEARCH}
  //       >
  //         Group list
  //       </a>{" "}
  //       and insert the link of the selected group below.
  //     </div>,
  //     "Imports up to 2500 results.",
  //     "Enter Group URL"
  //   ),
  //   createSearchInstructions(
  //     "/linkedin.svg",
  //     "LinkedIn group URL",
  //     "https://www.linkedin.com/groups/54066/",
  //     []
  //   ),
  //   SearchDefaultForm
  // ),
  createTab(
    SearchTabs.existing_search,
    undefined,
    "Existing search",
    PersonSearchIcon,
    createSearchComponent(
      "Existing search",
      "Select a list of people from an existing search you added in the past.",
      "",
      ""
    ),
    undefined,
    SearchExistingForm
  ),
];

const SHORT_NEW_SEARCH_TABS = [
  ALL_NEW_SEARCH_TABS[0],
  ALL_NEW_SEARCH_TABS[1],
  // ALL_NEW_SEARCH_TABS[2],
  // ALL_NEW_SEARCH_TABS[3],
];

const createSearchTypeFilterOptions = (id: SearchTypeFilters) => ({
  id: SearchTypeFilters[id],
  name: SearchMatchFilterLabel[id],
  query: { strategy: SearchMatchFilterStrategy[id] },
});

const SEARCH_TYPE_FILTER_OPTIONS = [
  createSearchTypeFilterOptions(SearchTypeFilters["linkedin_search"]),
  createSearchTypeFilterOptions(SearchTypeFilters["sales_nav"]),
  createSearchTypeFilterOptions(SearchTypeFilters["google_sheet"]),
  // createSearchTypeFilterOptions(SearchTypeFilters["group_search"]),
];

const createSearchStatusFilterOptions = (id: SearchStatusFilters) => ({
  id: SearchStatusFilters[id],
  name: SearchStatus[id],
  query: { status: SearchStatusMatchToVariant[id] },
});

const SEARCH_STATUS_FILTER_OPTIONS = [
  createSearchStatusFilterOptions(SearchStatusFilters.queue),
  createSearchStatusFilterOptions(SearchStatusFilters.processing),
  createSearchStatusFilterOptions(SearchStatusFilters.processed),
  createSearchStatusFilterOptions(SearchStatusFilters.failed),
];

const createSearchResultStatusFilterOptions = (
  id: SearchResultStatusFilters
) => ({
  id: SearchResultStatusFilters[id],
  name: SearchResultStatus[id],
  query: { status: SearchResultStatusMatchToVariant[id] },
});

const SEARCH_RESULT_STATUS_FILTER_OPTIONS = [
  createSearchResultStatusFilterOptions(SearchResultStatusFilters.queue),
  createSearchResultStatusFilterOptions(SearchResultStatusFilters.processed),
  createSearchResultStatusFilterOptions(SearchResultStatusFilters.failed),
];

const SEARCH_STRATEGY_MAX_RESULTS = {
  [SearchStrategy["inevitable.strategy.search.BasicStrategy"]]: 1000,
  [SearchStrategy["inevitable.strategy.search.SalesNavStrategy"]]: 2500,
  [SearchStrategy["inevitable.strategy.search.RecruiterStrategy"]]: 2500,
  [SearchStrategy["inevitable.strategy.search.GroupStrategy"]]: 1000,
  [SearchStrategy["inevitable.strategy.search.GoogleSheetsStrategy"]]:
    undefined,
  [SearchStrategy[
    "inevitable.strategy.search.MockDirectActionAddToCampaignStrategy"
  ]]: undefined,
};

const STATIC_HEADS = ["name", "actions"];

const SALES_NAV_COMPANY = "sales/search/company";

export {
  ALL_NEW_SEARCH_TABS,
  SHORT_NEW_SEARCH_TABS,
  SEARCH_TYPE_FILTER_OPTIONS,
  SEARCH_STATUS_FILTER_OPTIONS,
  SEARCH_RESULT_STATUS_FILTER_OPTIONS,
  SEARCH_STRATEGY_MAX_RESULTS,
  STATIC_HEADS,
  SALES_NAV_COMPANY,
};
