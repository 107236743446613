import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Theme, lighten } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import RouterConstants from "core/routes/constants";
import { MyNetworkTabs } from "modules/Person/models";
import {
  CZECH_DEFAULT_PLACEHOLDERS_ARR,
  DEFAULT_PLACEHOLDERS_ARR,
} from "modules/Placeholder/utils";
import { UserContext } from "modules/User/context";
import { AccountContext } from "modules/Account/context";
import PlaceholderButton from "../PlaceholderButton";
import DynamicPlaceholderMenu from "../DynamicPlaceholderMenu";
import { PlaceholderMenuProps } from "modules/Placeholder/models";
import AccountPlaceholderMenu from "../AccountPlaceholderMenu";

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    borderRadius: 0,
  },
  placeholderItem: {
    "&:hover": {
      backgroundColor: lighten(theme.palette.secondary.main, 0.7),
    },
  },
  placeholderMenu: {
    width: 180,
    maxHeight: 400,
    overflowY: "scroll",
    ...theme.app.constants.scrollbar,
  },
  box: {
    display: "inline-block",
    position: "relative",
    marginBottom: theme.spacing(3.5),
  },
}));

const createItem = (id: string) => ({ id, name: id });

const ContactPlaceholderMenu = (
  props: PlaceholderMenuProps
): React.ReactElement => {
  const { customPlaceholders, handleOnSelect } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { user } = React.useContext(UserContext);
  const {
    account: { time_zone },
  } = React.useContext(AccountContext);

  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={classes.box}>
      {(user.frontend_state.language_cz || time_zone === "Europe/Prague") &&
        CZECH_DEFAULT_PLACEHOLDERS_ARR.map(({ id, name }) => (
          <PlaceholderButton
            key={id}
            label={id}
            handleOnClick={() => handleOnSelect({ id, name })}
          />
        ))}
      {DEFAULT_PLACEHOLDERS_ARR.map(({ id, name }) => (
        <PlaceholderButton
          key={id}
          label={id}
          handleOnClick={() => handleOnSelect({ id, name })}
        />
      ))}
      <>
        <PlaceholderButton
          label="Custom"
          handleOnClick={handleMenuOpen}
          disabledButton
        />
        <Menu
          autoFocus={false}
          anchorEl={anchorEl}
          id="placeholder-menu"
          keepMounted
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          open={isMenuOpen}
          onClose={handleMenuClose}
          PaperProps={{
            className: classes.placeholderMenu,
          }}
        >
          <MenuItem
            className={clsx(classes.item, classes.placeholderItem)}
            onClick={() => {
              handleOnSelect(createItem(`{{NOW|weekday(locale=LOCALE)}}`));
              handleMenuClose();
            }}
            value="day"
          >
            Today's weekday
          </MenuItem>
          {customPlaceholders.map((option) => (
            <MenuItem
              className={clsx(classes.item, classes.placeholderItem)}
              onClick={() => {
                handleOnSelect(option);
                handleMenuClose();
              }}
              key={option.name}
              value={option.name}
            >
              {option.id}
            </MenuItem>
          ))}
          <Divider light />
          <MenuItem
            className={classes.item}
            component={Link}
            to={RouterConstants.MY_NETWORK.all(MyNetworkTabs.placeholders)}
          >
            Edit placeholders
          </MenuItem>
        </Menu>
        <AccountPlaceholderMenu handleOnSelect={handleOnSelect} />
        <DynamicPlaceholderMenu {...props} />
      </>
    </Box>
  );
};

export default ContactPlaceholderMenu;
