import React, { useMemo } from "react";
import { useQuery } from "react-query";
import get from "lodash/get";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { AccountContext } from "modules/Account/context";
import { ILimitItem, IRestriction } from "modules/Report/models";
import { getTodayRangeQuery, getLimitText } from "modules/Report/utils";
import { IAccount } from "modules/Account/models";

interface ReportItemProps extends ILimitItem {
  restrictions: IAccount["restrictions"] | undefined;
  restriction: IRestriction | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    padding: theme.spacing(3, 0),
    minWidth: 300,
    width: "100%",
  },
  text: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  limit: {
    display: "flex",
    alignItems: "center",
    "& > *": {
      marginLeft: theme.spacing(1.5),
    },
  },
  placeholder: {
    color: theme.app.palette.action.placeholder,
  },
  avatarContainer: {
    minWidth: 44,
  },
  avatar: {
    width: 28,
    height: 28,
  },
  avatarIcon: {
    fontSize: 18,
  },
  restriction: {
    display: "flex",
    alignItems: "center",
  },
}));

const LimitReportItem = ({
  label,
  name,
  func,
  limit,
  icon: Icon,
  color,
  restrictions,
  restrictionKey,
  restriction,
}: ReportItemProps): React.ReactElement => {
  const classes = useStyles();
  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);
  const query = getTodayRangeQuery();

  const fetchLimits = async () => {
    try {
      const { data } = await func(accountId, query);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data } = useQuery(
    ["limits", accountId, query, name],
    () => fetchLimits(),
    {
      keepPreviousData: true,
      staleTime: 60 * 1000, // Only refetch if older than 1 minute
    }
  );

  const limitValue = useMemo(() => {
    const defaultLimit = getLimitText(
      limit ? get(restrictions, limit) : undefined,
      name
    );
    if (restriction && restrictionKey) {
      return (
        <span className={classes.restriction}>
          {get(restriction, restrictionKey)}{" "}
        </span>
      );
    }
    return defaultLimit;
  }, [
    limit,
    restrictions,
    name,
    restriction,
    restrictionKey,
    classes.restriction,
  ]);

  const limitText = useMemo(
    () => restrictions && limitValue !== undefined,
    [restrictions, limitValue]
  );

  return (
    <ListItem className={classes.item}>
      <ListItemAvatar className={classes.avatarContainer}>
        <Avatar className={classes.avatar} sx={{ backgroundColor: color }}>
          <Icon className={classes.avatarIcon} />
        </Avatar>
      </ListItemAvatar>
      <div className={classes.text}>
        <Typography variant="subtitle2">{label}</Typography>
        <div className={classes.limit}>
          {data && data.count !== undefined ? (
            <Typography variant="body2">{data?.count}</Typography>
          ) : (
            <Skeleton width={25} />
          )}
          <Typography variant="body2" className={classes.placeholder}>
            /
          </Typography>
          {limitText ? (
            <Typography variant="body2" className={classes.placeholder}>
              {limitValue}
            </Typography>
          ) : (
            <Skeleton width={25} />
          )}
        </div>
      </div>
    </ListItem>
  );
};

export default LimitReportItem;
