import React, { useEffect } from "react";
import clsx from "clsx";
import { IconButton } from "@mui/material";
import Send from "@mui/icons-material/Send";
import { usePlaceholderEditor } from "./hooks/usePlaceholderEditor";
import { useStyles } from "./styles";
import ContactPlaceholderMenu from "../ContactPlaceholderMenu";
import { IMentionData } from "modules/Placeholder/models";
import PreviewView from "../PreviewView";

interface PlaceholderTextEditorProps {
  value: string;
  fieldValue: string | null;
  onChange: (value: string) => void;
  placeholder: string;
  error?: string;
  multiline?: boolean;
  rows?: number;
  maxLength?: number;
  enabledPreview?: boolean;
  endAdornmentSubmit?: boolean;
  allPlaceholders: IMentionData[];
  allCustomPlaceholders: IMentionData[];
}

const PlaceholderTextEditor: React.FC<PlaceholderTextEditorProps> = ({
  value,
  fieldValue,
  onChange,
  placeholder,
  error,
  multiline = false,
  enabledPreview,
  endAdornmentSubmit,
  allPlaceholders,
  allCustomPlaceholders,
}) => {
  const classes = useStyles();

  const {
    addPlaceholder,
    localValue,
    editorRef,
    handleInput,
    handleKeyDown,
    resetEditor,
  } = usePlaceholderEditor(value, onChange);

  useEffect(() => {
    if (fieldValue === null && localValue !== fieldValue) {
      resetEditor();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue]);

  return (
    <div className={classes.root}>
      {multiline && (
        <ContactPlaceholderMenu
          allPlaceholders={allPlaceholders}
          customPlaceholders={allCustomPlaceholders}
          handleOnSelect={(data) => addPlaceholder(data.name)}
          handleOnMultiSelect={(data) =>
            addPlaceholder(data.map((item) => item.name))
          }
        />
      )}

      <div className={classes.content}>
        <div
          ref={editorRef}
          contentEditable
          onInput={handleInput}
          onKeyDown={handleKeyDown}
          className={clsx(classes.editor, {
            [classes.preview]: enabledPreview,
            [classes.multiline]: multiline,
            [classes.singleline]: !multiline,
          })}
          data-placeholder={placeholder}
        />
        {endAdornmentSubmit && (
          <div className={classes.endAdornment}>
            <IconButton type="submit">
              <Send color={localValue.length ? "primary" : "inherit"} />
            </IconButton>
          </div>
        )}
        {error && <div className={classes.error}>{error}</div>}
        {multiline && <PreviewView template={localValue} />}
      </div>
    </div>
  );
};

export default PlaceholderTextEditor;
