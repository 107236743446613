import { createOption } from "core/utils/commonHandler";
import { ExecutionKeys, ExecutionLabels } from "../models";

const TIME_DELTA_OPTIONS = [
  createOption("00:15:00", "15 minutes"),
  createOption("00:30:00", "30 minutes"),
  createOption("01:00:00", "2 hours"),
  createOption("06:00:00", "6 hours"),
  createOption("1 00:00:00", "1 day"),
  createOption("2 00:00:00", "2 days"),
  createOption("3 00:00:00", "3 days"),
  createOption("7 00:00:00", "1 week"),
  // createOption("14 00:00:00", "2 weeks"),
];

const DEFAULT_TIME_DELTA = "1 00:00:00";

const createExecutionFilterOptions = (id: ExecutionKeys) => ({
  id: ExecutionKeys[id],
  name: ExecutionLabels[id],
  query: { execution: ExecutionKeys[id] },
});

const EXECUTION_FILTER_OPTIONS = [
  createExecutionFilterOptions(ExecutionKeys.RU),
  createExecutionFilterOptions(ExecutionKeys.ST),
  createExecutionFilterOptions(ExecutionKeys.DR),
];

export { TIME_DELTA_OPTIONS, EXECUTION_FILTER_OPTIONS, DEFAULT_TIME_DELTA };
