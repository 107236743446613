import RouterConstants from "core/routes/constants";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const AccountLogList = (): null => {
  const history = useHistory();

  useEffect(() => {
    history.push(RouterConstants.ROOT);
  }, [history]);

  return null;
};

export default AccountLogList;
