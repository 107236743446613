import React, { useMemo } from "react";
import { Control, FieldErrors } from "react-hook-form";
import ConfigConstant from "core/constants/ConfigConstant";
import { ActionStrategy, DirectActionStrategy } from "modules/Action/models";
import PlaceholderController from "modules/Placeholder/components/PlaceholderController";

interface ActionContentProps {
  control: Control;
  errors: FieldErrors;
  execution: ActionStrategy | DirectActionStrategy;
}

const ActionContent: React.FC<ActionContentProps> = React.memo(
  ({ control, errors, execution }) => {
    const editorProps = useMemo(() => {
      switch (execution) {
        case ActionStrategy[
          "inevitable.strategy.action.LinkedInConnectionRequestStrategy"
        ]:
          return {
            placeholder: "Message (optional)",
            multiline: true,
            rows: 4,
            maxLength: ConfigConstant.CHARACTER_LIMIT.CONNECTION_REQUEST,
          };
        case ActionStrategy[
          "inevitable.strategy.action.LinkedInMessageStrategy"
        ]:
        case ActionStrategy[
          "inevitable.strategy.action.LinkedInGroupMessageStrategy"
        ]:
          return {
            placeholder: "Message",
            multiline: true,
            rows: 4,
            maxLength: ConfigConstant.CHARACTER_LIMIT.MESSAGE,
          };
        default:
          return null;
      }
    }, [execution]);

    if (!editorProps) return null;

    return (
      <PlaceholderController
        name="strategy_data.template"
        control={control}
        error={errors.strategy_data?.template}
        {...editorProps}
      />
    );
  }
);

export default ActionContent;
